import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "wasVerstehtManUnterUmschuldung";

    const { question, answer } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${question} | miracl`} description={answer} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <p>
                    Wird ein neuer Kredit aufgenommen, um einen bestehenden zu tilgen, so spricht man von einer
                    Umschuldung. Während die Summe der Schulden dabei unverändert bleibt, kann damit beispielsweise der
                    Zinssatz oder die monatliche Rückzahlungsrate angepasst werden – letzteres etwa durch eine
                    Verlängerung der Laufzeit.
                </p>

                <p>
                    Zudem ist es auf diese Weise möglich, das aktuelle Kreditmodell zu adaptieren: So kann ein Kredit
                    mit variablem Zinssatz mithilfe einer Umschuldung durch einen Kredit mit Fixverzinsung ersetzt und
                    dadurch planbarer gestaltet werden.
                </p>

                <h4>Was spricht für eine Umschuldung?</h4>
                <p>
                    Grundsätzlich ist eine Umschuldung immer dann empfehlenswert, wenn ihre Kosten geringer ausfallen
                    als die durch den Kreditwechsel erzielte Ersparnis.
                </p>

                <h4>Wie bekomme ich eine Umschuldung?</h4>

                <ol>
                    <li>
                        Zunächst solltest du deinen derzeitigen Kredit analysieren und überlegen, ob dieser noch zu
                        deinen aktuellen Lebensumständen passt. Entscheidend ist dabei vor allem, ob die monatlichen
                        Raten für dich problemlos leistbar sind. Zudem solltest du die Zinsen deines Kredits mit den
                        derzeit auf dem Finanzmarkt üblichen Zinssätzen vergleichen sowie erwägen, ob du mit deinem
                        Zinsmodell zufrieden bist.
                    </li>

                    <br />

                    <li>
                        Anschließend solltest du dich darüber informieren, mit welchen Kosten der Kreditwechsel
                        verbunden ist – ob beispielsweise eine Vorfälligkeitsentschädigung, eine Grundbuchänderung oder
                        ein Notar zu bezahlen ist.
                    </li>

                    <br />

                    <li>
                        Jetzt geht es darum, eine passende Bank für die Optimierung deines Kredits zu finden. Hierbei
                        ist es empfehlenswert, sich von einem Wohnbau-Finanzexperten möglichst viele verschiedene
                        Angebote vorlegen zu lassen, wobei es jeweils sämtliche Kostenbestandteile (Zinssatz,
                        Bearbeitungs-, Kontoführungsgebühren etc.) im Blick zu behalten gilt.
                    </li>

                    <br />

                    <li>
                        Ist der Kreditvertrag unterzeichnet, so wird der bestehende Kredit von der neuen Bank zur Gänze
                        getilgt, wobei Sicherheiten wie die Hypothek im Grundbuch an diese überschrieben werden. Die
                        neue Bank erhält eine Löschungsquittung, die belegt, dass die bisherige Hypothek ihre Gültigkeit
                        verloren hat und veranlasst die Eintragung einer neuen.
                    </li>
                </ol>
            </Article>

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData
                page={key}
                heading={question}
                description={answer}
                datePublished="2022-09-12"
                dateModified="2022-09-12"
            />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
